@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom styles below */
@layer base {
  h1 {
    @apply text-3xl;
    @apply font-semibold;
    @apply leading-160;
  }
  h2 {
    @apply text-2xl;
    @apply font-semibold;
    @apply leading-160;
  }
  h3 {
    @apply text-xl;
    @apply font-semibold;
    @apply leading-160;
  }
  p,
  li,
  dt,
  dd,
  dl,
  ul,
  ol {
    @apply leading-160;
  }
  .text-small {
    @apply text-sm;
    @apply leading-160;
  }
  .text-medium {
    @apply text-base;
    @apply leading-160;
  }
  .text-medium-semibold {
    @apply text-base;
    @apply font-semibold;
    @apply leading-160;
  }
  .text-medium-- {
    @apply text-lg;
    @apply leading-160;
  }
  .text-medium-- {
    @apply text-xl;
    @apply leading-160;
  }
  .text-medium---semibold {
    @apply text-xl;
    @apply font-semibold;
    @apply leading-160;
  }
  .text-large {
    @apply text-2xl;
    @apply leading-160;
  }
  .text-large-semibold {
    @apply text-2xl;
    @apply leading-160;
  }
  .text-large- {
    @apply text-32;
    @apply leading-160;
  }
  .text-large--semibold {
    @apply text-32;
    @apply font-semibold;
    @apply leading-160;
  }
}
